@font-face {
font-family: '__myFont_f401bb';
src: url(/_next/static/media/b02a21f7299c9e0c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__myFont_f401bb';
src: url(/_next/static/media/0b7dddaa09986bfd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__myFont_f401bb';
src: url(/_next/static/media/c837c764a3965b20-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__myFont_f401bb';
src: url(/_next/static/media/9161728b7e1903a8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}@font-face {font-family: '__myFont_Fallback_f401bb';src: local("Arial");ascent-override: 111.54%;descent-override: 34.07%;line-gap-override: 0.00%;size-adjust: 88.04%
}.__className_f401bb {font-family: '__myFont_f401bb', '__myFont_Fallback_f401bb'
}

